<template>
  <div class="main">
    <div class="top">
      <img src="https://7ncdn.holdming.com/payTransition.png" alt="" />
    </div>
    <div class="middle">
      <div>1、该页面已经微信、支付宝核实支付安全，请放心使用</div>
      <div>2、如你支付遇到问题，请添加以下微信客服寻找帮助</div>
      <div class="red" v-if="disabled">系统检测订单未完成支付，请继续支付</div>
    </div>
    <div class="button">
      <div class="payAgainB" v-show="disabled">
      <!-- <div class="payAgainB"> -->
        <button
          class="payAgain"
          @click="payAgain"
          >继续支付</button
        >
      </div>
    </div>
    <div class="wechat">
      微信客服：qmwh10086
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      disabled: false,
      query: '',
      productNo: ''
    }
  },
  created () {
    this.query = this.$route.query
    if (this.query.state === "1") {
      // 错误信息
      this.showErr()
    } else {
      this.getUser();
    }
  },
  methods: {
    getUser () {
      var that = this
      var orderId = this.query.orderId
      try {
        this.$http.get('/measure/order/' + orderId).then((res) => {
          // 已支付
          this.productNo = res.data.body[0].productNo
          if (res.data.body[0].payStatus === 'PAYED') {
            // window.location.href = 'https://lucky.okfate.cn/#/guodu?orderId=' + orderId
            if (res.data.body[0].productNo === 2 || res.data.body[0].productNo === 5) {
              window.location.href = 'http://gxwh.kxiaodian.com/#/guodu?orderId=' + orderId
            } else {
              // window.location.href = 'https://pay.holdming.com/#/guodu?orderId=' + orderId
              window.location.href = 'https://pay.okfate.cn/#/guodu?orderId=' + orderId
            }
          } else {
            if (that.query.channel === 'alipay') {
              that.zhifubao()
            } else if (that.query.channel === 'wxpay') {
              that.wxpay()
            }
          }
        })
      } catch (err) {
        // console.log(err)
      }
    },
    // 支付宝支付
    zhifubao () {
      setTimeout(() => {
        this.finishPay()
      }, 1000)
      // 支付宝返回的是表单，插入到页面中直接提交就会调起支付宝支付页面(放弃支付时会回到之前的页面，支付宝不提供放弃支付时的页面跳转)
      const divForm = document.getElementsByTagName('divform')
      if (divForm.length) {
        document.body.removeChild(divForm[0])
      }
      const div = document.createElement('divform')
      div.innerHTML = this.query.params // res就是支付宝返回给你的form
      document.body.appendChild(div)
      document.forms[0].submit()
      this.firstTime = false
    },
    // 微信支付
    wxpay () {
      if (this.query.useJsApi === 'true') {
        // 微信内支付
        if (this.query.type === '0') {
          // 申请获取授权码
          window.location.href = this.query.params
        } else if (this.query.type === '2') {
          setTimeout(() => {
            this.weixinPay()
          }, 100)
          setTimeout(() => {
            this.disabled = true;
          }, 1500)
        } else if (this.query.type === '1') {
        }
      } else if (this.query.useJsApi === 'false') {
        // 微信外支付
        if (this.query.params === '') {
          this.finishPay()
        } else {
          // this.finishPay()
          // window.location.href = this.query.params
          window.location.replace(this.query.params)
        }
      }
    },
    finishPay () {
      var that = this
      setTimeout(() => {
        if (that.productNo === 2 || that.productNo === 5) {
          window.location.href = 'http://gxwh.kxiaodian.com/#/guodu?orderId=' + that.query.orderId
        } else {
          // window.location.href = 'https://pay.holdming.com/#/guodu?orderId=' + that.query.orderId
          window.location.href = 'https://pay.okfate.cn/#/guodu?orderId=' + that.query.orderId
        }
      }, 500)
    },
    payAgain () {
      if (this.query.channel === 'wxpay') {
        this.wxpay()
      } else if (this.query.channel === 'alipay') {
        this.zhifubao()
      }
    },
    // 解决微信内置对象报错
    weixinPay (params) {
      var that = this;
      if (typeof window.WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener(
            'WeixinJSBridgeReady',
            that.onBridgeReady(params),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            'WeixinJSBridgeReady',
            that.onBridgeReady(params)
          )
          document.attachEvent(
            'onWeixinJSBridgeReady',
            that.onBridgeReady(params)
          )
        }
      } else {
        that.onBridgeReady()
      }
    },
    // 微信内支付
    onBridgeReady () {
      if (typeof window.WeixinJSBridge === 'undefined') {
        this.wxpay()
      } else {
        this.weixinJSBridgePay()
      }
    },
    // JSBridge支付
    weixinJSBridgePay () {
      const that = this
      const params = JSON.parse(this.query.params)
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, // 公众号ID，由商户传入
          timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr: params.nonceStr, // 随机串
          package: params.package,
          signType: params.signType, // 微信签名方式
          paySign: params.paySign // 微信签名
        },
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠
            // window.console.log(res);
            if (that.productNo === 2 || that.productNo === 5) {
              window.location.href = 'http://pay.okfate.cn/#/guodu?orderId=' + that.query.orderId
            } else {
              // window.location.href = 'https://pay.holdming.com/#/guodu?orderId=' + that.query.orderId
              window.location.href = 'https://pay.okfate.cn/#/guodu?orderId=' + that.query.orderId
            }
          }
        }
      )
    },
    // 错误展示
    showErr () {
      const params = JSON.parse(this.query.params)
      alert(params.message)
    }
  }
}
</script>

<style scoped lang='scss'>
.main {
  height: 100vh;
  overflow: hidden;
  background-color: #ededed;
  .top {
    img {
      width: 100%;
    }
  }
  .middle {
    div {
      margin: 0 auto;
      width: 296px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
    div:first-child {
      margin-top: 38px;
      margin-bottom: 37px;
    }
  }
  .button {
    .payAgainB {
      width: 300px;
      margin: 30px auto 10%;
      .payAgain {
        border: none;
        width: 300px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #1fc966;
        border-radius: 5px;
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .finishPayB {
      width: 300px;
      margin: 0 auto;
      .finishPay {
        border: none;
        width: 300px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 5px;
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .wechat {
    width: 250px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 32px auto 0;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
  }
  .red {
    color: #b53627!important;
    margin-top: 20px!important;
    font-weight: bold!important;
    text-align: center;
  }
}
</style>
