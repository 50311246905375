<template>
  <div></div>
</template>

<script>
export default {
  created () {
    // console.log(this.$route.fullPath).substring(5);
    var str = this.$route.fullPath
    // console.log(str.substring(22));
    var str1 = str.substring(22)
    // console.log(str1);
    window.location.href = 'https://pay.holdming.com/#/payTransition' + str1
  }
}
</script>

<style>

</style>
