<template>
  <div class="main">
    <div class="success">
      <img src="https://7ncdn.holdming.com/guodu.png" alt="">
    </div>
    <div class="wait">
      请勿关闭页面，否则将丢失订单内容
    </div>
    <div class="kefutext">
      如支付遇到问题，请与客服联系
    </div>
    <div class="kefu">
      微信客服：qmwh10086
    </div>
  </div>
</template>

<script>
export default {
  created () {
    setTimeout(() => {
      this.getUser()
    }, 2000)
  },
  methods: {
    getUser () {
      var orderId = this.$route.query.orderId
      try {
        this.$http.get('/measure/order/' + orderId)
          .then((res) => {
            // console.log(res.data.body[0].productNo);
            // if (productNo === 2) {
            //   window.location.href = "http://gxwh.kxiaodian.com/#/liunianyunchengks/result?orderId=" + this.$route.query.orderId;
            // } else if (productNo === 5) {
            //   window.location.href = "http://gxwh.kxiaodian.com/#/baziyinyuanks/result?orderId=" + this.$route.query.orderId;
            // }
            // 已支付
            if (res.data.body[0].payStatus === 'PAYED') {
              if (res.data.body[0].productNo === 0) {
                window.location.href = 'https://lucky.okfate.cn/#/liunianyuncheng/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/liunianyuncheng/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 2) {
                // window.location.href = "http://gxwh.kxiaodian.com/#/liunianyunchengks/result?orderId=" + orderId;
                window.location.href = "http://gxwh.kxiaodian.com/#/liunianyunchengks/result?orderId=" + orderId;
                // window.location.href = 'https://lucky.holdming.com/#/liunianyunchengks/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 3) {
                window.location.href = 'https://lucky.okfate.cn/#/liunianyunchengvivo/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/liunianyunchengvivo/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 4) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuan/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuan/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 5) {
                // window.location.href = 'https://lucky.okfate.cn/#/baziyinyuanks/result?orderId=' + orderId
                window.location.href = "http://gxwh.kxiaodian.com/#/baziyinyuanks/result?orderId=" + orderId;
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuanks/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 6) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuanvivo/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuanvivo/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 7) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuanoppo/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuanoppo/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 8) {
                window.location.href = 'https://lucky.okfate.cn/#/hunianshengxiao/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/hunianshengxiao/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 9) {
                window.location.href = 'https://lucky.okfate.cn/#/hunianshengxiaoks/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/hunianshengxiaoks/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 12) {
                window.location.href = 'https://lucky.okfate.cn/#/bazihehun/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/bazihehun/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 13) {
                window.location.href = 'https://lucky.okfate.cn/#/bazijingpi/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/bazijingpi/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 14) {
                window.location.href = 'https://lucky.okfate.cn/#/bazihehuntg/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/bazihehuntg/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 15) {
                window.location.href = 'https://lucky.okfate.cn/#/bazijingpitg/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/bazijingpitg/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 16) {
                window.location.href = 'https://lucky.okfate.cn/#/liudaolunhui/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/liudaolunhui/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 20) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuan2023/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuan2023/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 21) {
                window.location.href = 'https://lucky.okfate.cn/#/tunianshengxiao/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/tunianshengxiao/result?orderId=' + orderId
              } else if (res.data.body[0].productNo === 22) {
                window.location.href = 'https://lucky.okfate.cn/#/longnianshengxiao/result?orderId=' + orderId
                // window.location.href = 'https://lucky.holdming.com/#/longnianshengxiao/result?orderId=' + orderId
              }
            } else {
              if (res.data.body[0].productNo === 0) {
                window.location.href = 'https://lucky.okfate.cn/#/liunianyuncheng/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/liunianyuncheng/pay?pay=0&orderId=' + orderId
              } else if (res.data.body[0].productNo === 2) {
                // window.location.href = 'http://gxwh.kxiaodian.com/#/liunianyunchengks/pay?orderId=' + orderId + '&pay=0'
                window.location.href = 'https://lucky.holdming.com/#/liunianyunchengks/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 3) {
                window.location.href = 'https://lucky.okfate.cn/#/liunianyunchengvivo/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/liunianyunchengvivo/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 4) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuan/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuan/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 5) {
                window.location.href = 'http://gxwh.kxiaodian.com/#/baziyinyuanks/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuanks/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 6) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuanvivo/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuanvivo/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 7) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuanoppo/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuanoppo/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 8) {
                window.location.href = 'https://lucky.okfate.cn/#/hunianshengxiao/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/hunianshengxiao/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 9) {
                window.location.href = 'https://lucky.okfate.cn/#/hunianshengxiaoks/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/hunianshengxiaoks/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 12) {
                window.location.href = 'https://lucky.okfate.cn/#/bazihehun/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/bazihehun/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 13) {
                window.location.href = 'https://lucky.okfate.cn/#/bazijingpi/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/bazijingpi/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 14) {
                window.location.href = 'https://lucky.okfate.cn/#/bazihehuntg/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/bazihehuntg/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 15) {
                window.location.href = 'https://lucky.okfate.cn/#/bazijingpitg/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/bazijingpitg/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 16) {
                window.location.href = 'https://lucky.okfate.cn/#/liudaolunhui/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/liudaolunhui/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 20) {
                window.location.href = 'https://lucky.okfate.cn/#/baziyinyuan2023/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/baziyinyuan2023/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 21) {
                window.location.href = 'https://lucky.okfate.cn/#/tunianshengxiao/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/tunianshengxiao/pay?orderId=' + orderId + '&pay=0'
              } else if (res.data.body[0].productNo === 22) {
                window.location.href = 'https://lucky.okfate.cn/#/longnianshengxiao/pay?orderId=' + orderId + '&pay=0'
                // window.location.href = 'https://lucky.holdming.com/#/longnianshengxiao/pay?orderId=' + orderId + '&pay=0'
              }
            }
          })
      } catch (err) {
        // console.log(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100vh;
  width: 100%;
  background-color: #FFEFD9;
  .success {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .wait {
    text-align: center;
    height: 17px;
    line-height: 17px;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #901C02;
    opacity: 0.9;
    margin-top: 21px;
  }
  .kefutext {
    text-align: center;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    margin-top: 18px;
  }
  .kefu {
    width: 250px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.7);
    border-radius: 20px;
    margin: 20px auto 0;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>
