import Vue from 'vue'
import VueRouter from 'vue-router'
import payTransition from '../views/payTransition/index.vue'
import payTransitionRedirect from '../views/payTransitionRedirect/index.vue'
import guodu from '../views/guodu/guodu.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/payTransition',
    name: 'payTransition',
    component: payTransition
  },
  {
    path: '/payTransitionRedirect',
    name: 'payTransitionRedirect',
    component: payTransitionRedirect
  },
  {
    path: '/guodu',
    name: 'guodu',
    component: guodu
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
