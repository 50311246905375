import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// export default vConsole

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
